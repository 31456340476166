<template>
  <v-card class="rounded-lg" flat>
    <div class="d-flex px-4 py-4">
      <div
        class="key-number font-weight-bold rounded-circle cyan lighten-5 rounded tp-border-thin pa-3 mr-3"
      >
        {{ itemIndex + 1 }}
      </div>

      <div class="mr-4 flex-grow-1">
        <div class="product-preview d-flex align-center mb-3">
          <template v-if="item.product">
            <div class="product-preview__img-wrapper rounded">
              <v-img
                class="rounded"
                :src="item.product.thumbnail"
                :aspect-ratio="1"
                :width="80"
              ></v-img>
            </div>
            <div class="flex-grow-1 ml-2">
              <v-list-item-title class="font-weight-bold"
                >{{ item.product.name }}
              </v-list-item-title>
              <div class="grey--text text--darken-2 text-body-2">
                {{ item.product.code }}
              </div>
            </div>
            <v-hover v-slot:default="{ hover }">
              <div
                class="tp-btn--text text-body-2 ml-2"
                :class="{ 'text-decoration-underline': hover }"
                @click="removeProduct"
              >
                Xóa
              </div>
            </v-hover>
          </template>

          <v-card
            v-else
            class="product-preview__empty_card d-flex align-center justify-center grey lighten-5"
            flat
          >
            <div class="text-center grey--text text--darken-2">
              Chưa có sản phẩm được chọn
            </div>
          </v-card>
        </div>

        <v-autocomplete
          v-model="item.product"
          class="text-body-1"
          clearable
          dense
          flat
          hide-details
          :items="filteredSearchProducts"
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          no-filter
          outlined
          placeholder="Nhập mã, tên sản phẩm"
          return-object
          :search-input.sync="productSearchKey"
        >
          <template v-slot:item="data">
            <v-list-item-content :title="data.item.name">
              <v-list-item-title
                class="font-weight-bold"
                v-html="data.item.name"
              ></v-list-item-title>
              <div
                class="grey--text text--darken-2 text-body-2"
                v-text="data.item.code"
              ></div>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
    </div>
  </v-card>
</template>

<script>
import { debounce } from "@/core/utils/debounce";

export default {
  props: {
    item: {
      type: Object
    },
    itemIndex: {
      type: Number
    }
  },

  data() {
    return {
      productSearchKey: null
    };
  },

  computed: {
    filteredSearchProducts() {
      return this.searchProducts.filter(
        item => !this.pageSectionBuildProductIds.includes(item.id)
      );
    },
    pageSectionBuildProductIds() {
      return this.$store.getters[
        "PAGE_SECTION_BUILD/pageSectionBuildProductIds"
      ];
    },
    searchProducts() {
      return this.$store.getters["PRODUCT/searchProducts"];
    }
  },

  watch: {
    productSearchKey(val) {
      if (val !== null && val.length > 0) {
        this.debounceGetSearchProducts();
      }
    }
  },

  created() {
    this.debounceGetSearchProducts = debounce(this.getSearchProducts, 700);
  },

  methods: {
    getSearchProducts() {
      this.$store.dispatch("PRODUCT/searchProducts", {
        search: this.productSearchKey
      });
    },

    removeProduct() {
      this.item.product = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.key-number {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  height: 36px;
  margin-right: 0.5rem;
  min-width: 36px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 36px;
}

.product-preview {
  height: 80px;
  min-height: 80px;
  width: 100%;

  &__img-wrapper {
    border: 1px solid #e5e5e5;
    width: 80px;
  }

  &__empty_card {
    height: 100%;
    width: 100%;
  }
}
</style>
