<template>
  <div>
    <!-- Start: Top -->
    <div class="d-flex align-center">
      <div class="text-h5 font-weight-bold">Build top laptop</div>
      <v-spacer></v-spacer>
      <v-btn
        class="rounded-lg"
        color="primary"
        depressed
        @click="updatePageSectionBuild"
      >
        Lưu
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: List keywords -->
    <v-row class="mt-2">
      <v-col
        v-for="(item, index) in pageSectionBuild.products"
        :key="index"
        cols="12"
        md="6"
      >
        <card-form-product :item="item" :item-index="index"></card-form-product>
      </v-col>
    </v-row>
    <!-- Start: List keywords -->
  </div>
</template>

<script>
import CardFormProduct from "./components/CardFormProduct";

export default {
  components: {
    CardFormProduct
  },

  computed: {
    pageSectionBuild() {
      return this.$store.getters["PAGE_SECTION_BUILD/pageSectionBuild"];
    },
    pageSectionBuildStatusRequest() {
      return this.$store.getters["PAGE_SECTION_BUILD/statusRequest"];
    }
  },

  async created() {
    await this.$store.dispatch("PAGE_SECTION_BUILD/getPageSectionBuildById", 1);
  },

  methods: {
    async updatePageSectionBuild() {
      await this.$store.dispatch("PAGE_SECTION_BUILD/updatePageSectionBuild", {
        ...this.pageSectionBuild
      });

      // Alert
      if (
        this.pageSectionBuildStatusRequest.value ===
        "success-updatePageSectionBuild"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    }
  }
};
</script>
