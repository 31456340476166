import { render, staticRenderFns } from "./CardFormProduct.vue?vue&type=template&id=2761f6ac&scoped=true&"
import script from "./CardFormProduct.vue?vue&type=script&lang=js&"
export * from "./CardFormProduct.vue?vue&type=script&lang=js&"
import style0 from "./CardFormProduct.vue?vue&type=style&index=0&id=2761f6ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2761f6ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VCard,VHover,VImg,VListItemContent,VListItemTitle})
